<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :modal-show="modal.show"
      :notificationMessage.sync="notificationMessage"
      :notificationTime="notificationTime"
      :notificationType="notificationType"
    >

      <template #notification>
        {{notification.message}}
      </template>

      <template #modal>
        <modal-confirmation
          title="Discard Data?"
          description="Are you sure want to go back? All your unsaved data will be lost"
          text-confirm="Discard"
          text-cancel="Cancel"
          @cancel="modalCancel"
          @confirm="modalConfirm"
        />
      </template>

      <template #title>
        Create Checklist
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">
          <!-- Image -->
          <div class="flex flex-col items-center justify-center flex-shrink-0 h-40 mb-5">
            <img
              v-if="picture"
              :src="picture"
              class="`
                object-scale-down h-40 mt-3 bg-transparent rounded-md w-72 md:w-80 lg:w-96
                flex-shrink-0 self-center
              `"
              alt="Checklist Image"
            >
            <p
              v-if="!picture"
              class="text-xl font-bold px-7"
              :class="[validateInputs ? 'text-red-500' : 'text-content']"
            >
              Please insert an image before creating the checklist
            </p>
          </div>

          <!-- List Info -->
          <form @submit.prevent="createChecklist" class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <text-input-component
              :value.sync='checklist.name'
              :valid='validateInputs ? checklistValidity.nameValid : true'
              label='Checklist Name'
              mode="form"
            />
            <dropdown-component
              :valid='validateInputs ? checklistValidity.typeValid : true'
              :value.sync='checklist.type'
              :items="['Service', 'Safety']"
              label='Type'
              type='text'
              class="pt-5"
              mode="form"
            />
            <button class="hidden" />
          </form>

          <!-- Actions -->
          <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <button-inline
              v-if="picture"
              @click="takePicture"
              label="Pictures"
              value="Add"
              mode="edit"
              :disabled="false"
            />
            <button-inline
              v-if="!picture"
              @click="takePicture"
              label="Pictures"
              value="Update"
              mode="edit"
              :valid="false"
              :disabled="false"
            />
            <input
                @change="replacePicture" v-show='false'
                type="file" accept="image/*"
              />
          </div>

          <button-large
            @click="createChecklist()"
            color="green"
            class="self-center"
            :disabled="creatingChecklist"
          >
            Create Checklist
          </button-large>
          <div class="h-10 opacity-0">space...</div>

        </div>
      </template>
    </interior-page-content-container>
</template>

<script>

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import ButtonInline from '@/components/shared/Buttons/ButtonInline.vue';
import DropdownComponent from '@/components/shared/inputs/DropdownComponent.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

import {
  ADMIN_CHECKLIST_POST,
} from '@/store/actions';

export default {
  name: 'ChecklistCreate',
  components: {
    InteriorPageContentContainer,
    ModalConfirmation,
    TextInputComponent,
    ButtonInline,
    DropdownComponent,
    ButtonLarge,
  },
  data() {
    return {
      checklist: {
        name: '',
        type: '',
        picture: null,
      },
      checklistValidity: {
        nameValid: false,
        typeValid: false,
        pictureValid: false,
      },
      modal: {
        show: false,
      },
      validateInputs: false,
      edited: false,
      actionComplete: false,
      creatingChecklist: false,
      to: this.defaultBackRoute,
      notification: {
        show: false,
        type: 'alert',
        message: '',
      },
      notificationMessage: '',
      notificationTime: 5000,
      notificationType: 'success',
    };
  },
  computed: {
    defaultBackRoute() {
      return { name: 'AdminChecklists' };
    },
    picture() {
      return this.checklist.picture ? this.pictureUrl(this.checklist.picture) : null;
    },
    valid() {
      return this.checklistValidity.nameValid
        && this.checklistValidity.typeValid && this.checklistValidity.pictureValid;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.edited && !this.actionComplete) {
      this.to = to;
      this.modal.show = true;
    } else next();
  },
  watch: {
    checklist: {
      handler(newVal) {
        this.checklistValidity.nameValid = newVal.name.length > 0;
        this.checklistValidity.typeValid = ['Service', 'Safety'].includes(newVal.type);

        if (this.checklist.name !== '') this.edited = true;
        else if (this.checklist.type !== '') this.edited = true;
        else this.edited = false;
      },
      deep: true,
    },
  },
  methods: {
    async createChecklist() {
      this.validateInputs = true;
      if (!this.valid) return;
      this.edited = false;
      this.creatingChecklist = true;
      this.$store.dispatch(ADMIN_CHECKLIST_POST, {
        name: this.checklist.name,
        type: this.checklist.type,
        picture: this.checklist.picture,
      })
        .then((newChecklist) => {
          this.actionComplete = true;
          this.$router.push({
            name: 'AdminChecklist',
            params: {
              checklist_id: newChecklist.id,
            },
          });
        })
        .catch((error) => {
          // TODO Test this properly, make the http requests
          //  fail and then do it or something
          if (error.response.status === 400) {
            this.notificationMessage = error.response.data;
            this.notificationType = 'alert';
            this.notificationType = 7000;
          } else {
            this.notificationMessage = 'An error has occured. Please try again later.';
            this.notificationType = 'alert';
            this.notificationType = 7000;
          }
        });
    },
    modalConfirm() {
      this.actionComplete = true;
      this.$router.push(this.to);
    },
    modalCancel() {
      this.modal.show = false;
      this.to = this.defaultBackRoute;
    },
    backNav() {
      this.$router.push(this.defaultBackRoute);
    },

    async getPictureFile() {
      return this.checklist.picture;
    },
    pictureUrl(obj) {
      return typeof obj === typeof '' ? obj : URL.createObjectURL(obj);
    },
    replacePicture(event) {
      this.checklistValidity.pictureValid = true;
      this.checklist = {
        ...this.checklist,
        picture: event.target.files[0],
      };
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },
  },

};
</script>
